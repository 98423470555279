import "./Slider.scss";
import { Fragment, forwardRef, useState } from "react";
import { ReactComponent as IconNext } from "../../assets/v3/icon-next.svg";
import { ReactComponent as IconPrev } from "../../assets/v3/icon-prev.svg";

const SliderV2 = forwardRef(
	(
		props: {
			length: number;
			children: any;
			setCurrent: any;
		},
		ref: any
	) => {
		const [movement, setMovement] = useState<number>(0);
		const [transitionDuration, setTransitionDuration] = useState<number | string>(0);
		const [lastTouch, setLastTouch] = useState<number>(0);
		const [percentageSlided, setPercentageSlided] = useState<number>(0);
		const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
		let transitionTimeout: any;

		const handleMovement = (delta: number) => {
			clearTimeout(transitionTimeout);
			const maxLength = props.length - 1;
			setPercentageSlided((delta / ref.current.clientWidth) * 100);
			const baseSlidePercentage = 100 * currentSlideIndex;
			let nextMovement = 100 * (delta / ref.current.clientWidth) + baseSlidePercentage; // 500px / 2200px 0.25 => 25%
			if (nextMovement > maxLength * 100) {
				nextMovement = maxLength * 100;
			} else if (nextMovement <= 0) {
				nextMovement = 0;
			}
			setMovement(nextMovement);
			setTransitionDuration("Os");
		};

		const handleTouchStart = (e: any) => {
			setPercentageSlided(0);
			setLastTouch(e.nativeEvent.touches[0].clientX);
		};

		const handleTouchMove = (e: any) => {
			const delta = lastTouch - e.nativeEvent.touches[0].clientX;
			handleMovement(delta);
		};

		const handleTouchEnd = () => {
			if (Math.abs(percentageSlided) >= 5) {
				if (percentageSlided > 0 && currentSlideIndex + 1 <= props.length - 1) {
					transitionTo(currentSlideIndex + 1, 0.5);
				} else if (percentageSlided < 0 && currentSlideIndex - 1 >= 0) {
					transitionTo(currentSlideIndex - 1, 0.5);
				}
			}
			setLastTouch(0);
		};

		const transitionTo = (index: number, duration: number) => {
			props.setCurrent(index);
			setCurrentSlideIndex(index);
			setMovement(index * 100);
			setTransitionDuration(`${duration}s`);
			transitionTimeout = setTimeout(() => {
				setTransitionDuration("0s");
			}, duration * 100);
		};

		return (
			<Fragment>
				{
					<IconPrev
						className="main-slider__arrow main-slider__arrow--prev"
						style={{ visibility: (currentSlideIndex === 0 ? 'hidden' : 'visible') }}
						onClick={() => {
							transitionTo(currentSlideIndex - 1, 0.5);
						}}
					/>
				}

				<div
					onDragStart={(e) => e.preventDefault()}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					ref={ref}
					className="main-slider"
				>
					<div
						style={{
							transform: `translateX(${movement * -1}%) `,
							transitionDuration: `${transitionDuration}`,
						}}
						className="main-slider__swiper"
					>
						{props.children}
					</div>
				</div>
				{
					<IconNext
						className="main-slider__arrow main-slider__arrow--next"
						style={{ visibility: (currentSlideIndex === props.length - 1 ? 'hidden' : 'visible') }}
						onClick={() => {
							transitionTo(currentSlideIndex + 1, 0.5);
						}}
					/>
				}
			</Fragment>
		);
	}
);

export default SliderV2;
