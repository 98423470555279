import "./HomeLeftArea.scss";
import { ReactComponent as MuslimShowIcon } from "assets/v3/ms-app-name.svg";
import HooPowAppMenuV3 from "components/elements/common/internalrouter/HooPowAppMenuV3";
import { ReactComponent as HelpUsBtn } from "assets/v3/helpus-btn.svg";
import { useContext } from "react";
import { UserContext } from "components/utils/UserContext";
import { BlockerContext } from "components/utils/BlockerContext";

const HomeLeftArea = () => {
	const { setBlock } = useContext(BlockerContext);
	const { freemiumEnabled, isUserAuthenticated } = useContext(UserContext);

	return (
		<>
			{process.env.REACT_APP_FEATURE_PAUSE !== '1' && freemiumEnabled && !isUserAuthenticated && isUserAuthenticated !== undefined && (
				<HelpUsBtn  className="ms-home-helpus ms-clickable" onClick={() => setBlock((prev: boolean) => !prev)}/>
			)}
			<div className="ms-home-icon-v3"> <MuslimShowIcon /> </div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default HomeLeftArea;
