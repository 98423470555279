import "./App.scss";
import HomepageV3Component from "./components/v3/Homepage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Secured from "Secured";
import UserContextProvider from "components/utils/UserContext";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import BlockerContextProvider from "components/utils/BlockerContext";
import MainContextProvider from "components/utils/MainContext";
import HomepageComponent from "./components/Homepage";
import BdComponent from "./components/Bd";
import UserAnnouncement from "components/elements/common/phoneblocked/UserAnnouncement";

const signUpFields = [
	{
		type: "name",
		label: "Name",
		placeholder: "Enter your name",
		hint: null,
		required: true,
	},
	{
		type: "email",
		label: "Email Address *",
		required: true,
	},
	{
		type: "password",
		label: "Password *",
		required: true,
	},
];

function App() {
	return (<>
		<UserAnnouncement />
		<UserContextProvider>
			<BlockerContextProvider>
				<MainContextProvider>
					<Secured>
						{process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? (
							<Router>
								<Switch>
									<Route path="/" exact render={HomepageV3} />
									<Route path="/:bdId" exact render={HomepageV3} />
									{process.env.REACT_APP_DOMAIN_NAME === 'localhost' && (
										<Route path="/login" exact render={Login} />
									)}
								</Switch>
							</Router>
						) : (
							<Router>
								<Switch>
									<Route path="/" exact render={Homepage} />
									<Route path="/bd/:bdId" exact render={Bd} />
								</Switch>
							</Router>
						)}
					</Secured>
				</MainContextProvider>
			</BlockerContextProvider>
		</UserContextProvider>
	</>);
}

export default App;

const Homepage = (props: any) => (
	<HomepageComponent {...props} />
)

const Bd = (props: any) => (
	<BdComponent {...props} />
);


const HomepageV3 = (props: any) => (
	<HomepageV3Component {...props} />
);

const Login = () => (
	<AmplifyAuthenticator usernameAlias="email">
		<AmplifySignUp
			usernameAlias="email"
			formFields={signUpFields}
			slot="sign-up"
		/>
	</AmplifyAuthenticator>
);
