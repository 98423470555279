import { Storage } from 'aws-amplify';
import FileDS from './FileDS';

const responseMap: any = {};

export default class ImageDataStore {

  static getUrlFile = (key: string, callback: Function) => {
    callback(`${process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL}/${key}`);
  };  

  static getUrl = (key: string) => {
    return `${process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL}/${key}`;
  };  
  
  static getUrlFileFromHoopow = (key: string) => {
    return `${process.env.REACT_APP_HOOPOW_IMAGES_API_ENPOINT_BASE_URL}/${key}`;
  };

  static getUrlImage(key: string, callback: Function) {
    if (process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL) {
      return ImageDataStore.getUrlFile(key, callback);
    } else {
      FileDS.getSecuredUrlFile(key, (url: string) => {
        callback(url);
      });
    }
  }

  static getImage(key: string, callback: Function) {
    if (responseMap[key]) {
      callback(responseMap[key]);
    } else {
      Storage.get(key, {
        customPrefix: {
          public: ''
        },
        download: true
      }).then((response: any) => {
        responseMap[key] = response;
        callback(response);
      }).catch((err) => {
        console.log(err);
        callback(undefined, err);
      });
    }
  }

}

