import "./MainContent.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { TBd, TBdImage } from "../../types";
import BaseDS from "../../datastore/BaseDS";
import ImageComponent from "../elements/ImageComponent";
import ImageDataStore from "../../datastore/ImageDataStore";
import Slide from "./Slide";
import Slider from "./Slider";
import { useGA4React } from "ga-4-react";
import { ReactComponent as IconReduceScreen } from "../../assets/icon_reduceScreen.svg";
import { ReactComponent as IconFullScreen } from "../../assets/v3/icon-fullscreen.svg";
import { ReactComponent as IconLock } from "../../assets/v3/icon-lock.svg";
import { TiHome } from "react-icons/ti"
import { Link } from "react-router-dom"
import { BlockerContext } from "../utils/BlockerContext";
import { UserContext } from "../utils/UserContext";
import { MainContext } from "components/utils/MainContext";
import { ClockLoader as Loader } from "react-spinners";

const MainContent = (props: any) => {
	const { setBlock } = useContext(BlockerContext);
	const { userHasAccess } = useContext(UserContext);
	const { allBd } = useContext(MainContext);
	const length = props.allDataBdImage?.bdImage?.length || 0;
	const sliderWrapper = useRef<any>();
	const ga: any = useGA4React();
	const { setAllDataBdImage, current, setCurrent } = props;
	const [loading, setLoading] = useState<boolean>();
	const bdId = props.match.params.bdId;

	const addImageUrlToData = (bd: TBdImage) => {
		return new Promise((resolve) => {
			ImageDataStore.getUrlImage(bd.bdImageFr || "", (url: string) => {
				bd.imageUrl = url;
				resolve(url);
			});
		});
	};

	useEffect(() => {
		if (allBd && allBd.length) {
			const bdIdToLoad = bdId ? bdId : allBd[0].id;
			if (!userHasAccess(bdIdToLoad) && bdIdToLoad !== allBd[0].id) document.location.href = '/';
			setLoading(true);
			setCurrent(0);
			BaseDS.getFullBdById(bdIdToLoad).then((data: TBd) => {
				if (data.bdImage) {
					let promises: any = [];
					data.bdImage.forEach((bd) => {
						if (bd.bdImageFr) {
							promises.push(addImageUrlToData(bd));
						}
					});
					Promise.all(promises).then(() => {
						setAllDataBdImage(data);
					});
				} else {
					setAllDataBdImage(data);
				}
			}).finally(() => setLoading(false));
		}
	}, [bdId, allBd, setAllDataBdImage, userHasAccess, setCurrent]);

	useEffect(() => {
		if (props.allDataBdImage && ga) {
			const title = document.title.split(" - ")[0] + " - " + props.allDataBdImage.name;
			if (title !== document.title) {
				document.title =
					document.title.split(" - ")[0] + " - " + props.allDataBdImage.name;
				ga.pageview(document.location.pathname, undefined, document.title);
			}
		}
	}, [props.allDataBdImage, ga]);

	const nextImgUrl = (index: number) => {
		if (index + 1 < length && props.allDataBdImage?.bdImage) {
			return props.allDataBdImage.bdImage[index + 1].imageUrl;
		}
		return "";
	};

	return (
		<div className="ms-homepage-v3-background">
			<div className="ms-homepage-v3">
				<IconReduceScreen onClick={props.toggleFullScreen} className="main-slider__reduce-screen" />
				<div className="ms-homepage-v3__container-content">
					<IconFullScreen onClick={props.toggleFullScreen} className="main-slider__full-screen" />
					{loading ? (
						<div className="loader-bd loader-bd-thumbnail force-display">
							<Loader />
						</div>
					) : (<>
						<span className="ms-homepage-v3__imageTitle">{props.allDataBdImage?.bdTitle}</span>
						<Slider ref={sliderWrapper} length={length} setCurrent={setCurrent}>
							{props.allDataBdImage?.bdImage?.map((item: any, index: number) => {
								return (
									<React.Fragment key={index}>
										<Slide>
											<div className="ms-homepage-v3__imageWrapper">
												<ImageComponent
													className="ms-homepage-v3__image"
													imageSrc={item.imageUrl}
													width="100%"
													height="100%"
												/>

											</div>
											<div style={{ display: "none" }}>
												<ImageComponent
													className="ms-homepage-v3__image ms-homepage-v3__image--hidden"
													imageSrc={nextImgUrl(index)}
												/>
											</div>
										</Slide>
									</React.Fragment>
								);
							})}
						</Slider>
					</>)}
					{!props.isFulscreen && (
						<div className="ms-homepage-v3__bottom">
							<div className="ms-homepage-v3__bottom__dots">
								{props.allDataBdImage?.bdImage?.map((item: any, index: number) => {
									return (
										<React.Fragment key={index}>
											<span
												key={index}
												className={
													index === current
														? "ms-homepage-v3__bottom__dot dot-active"
														: "ms-homepage-v3__bottom__dot"
												}
											></span>
										</React.Fragment>
									);
								})}
							</div>
						</div>
					)}
				</div>
				<div className={"ms-allBd"}>
					<Link to="/" className="ms-allBd__home-return">
						<TiHome />
						<p>Accueil</p>
					</Link>
					{allBd.slice(0, 50).map((item: any, index: number) => {
						return (
							<Link
								onDragStart={(e: any) => e.preventDefault()}
								to={`/${item.id}`}
								key={item.bdKey}
								onClick={(event: any) => {
									if (!userHasAccess(item.id) && index > 0) {
										event.preventDefault();
										setBlock(true);
										return false;
									} else if (bdId !== item.id) {
										setAllDataBdImage(undefined);
									}
								}}
								className={`${!userHasAccess(item.id) && index > 0 && 'ms-bd__item-blocked'}`}
							>
								<ImageComponent
									className="ms-allBd__bd-list"
									imageKey={item.imageHomepage}
								/>
								<span>{item.bdTitle}</span>
								<div className="overlay"><IconLock /></div>
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default MainContent;
