import HooPowDS from "components/elements/common/internalrouter/HooPowDS";
import BaseDS from "datastore/BaseDS";
import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";

const removeAllDash = (value: string) => {
	return value ? value.replace(/-/g, "") : value;
};

export const MainContext = createContext<any>("");

const MainContextProvider = (props: any) => {
	const { isUserAuthenticated } = useContext(UserContext);
	const [applications, setApplications] = useState<any[]>();
	const [allBd, setAllBd] = useState<any>([]);
	const [mainConfig, setMainConfig] = useState<any>([]);

	useEffect(() => {
		HooPowDS.getPlatformDef().then((platformDef: any) => {
			setApplications(platformDef.application.filter((appDef: any) => appDef.publicationDate < new Date().toISOString()));
		});
	}, []);

	const getAppConfig = () => {
		return applications?.filter(app => app.appId === process.env.REACT_APP_ID)[0]
	}

	useEffect(() => {
		if (isUserAuthenticated !== undefined) {
			BaseDS.getAllBds().then((data: any) => {
				setMainConfig(data);
				data.bd.sort(
					(a: any, b: any) =>
						parseInt(removeAllDash(b.publicationDate)) -
						parseInt(removeAllDash(a.publicationDate))
				);
				setAllBd(data.bd);
			});
		}
	}, [isUserAuthenticated]);

	return (
		<MainContext.Provider value={{ getAppConfig, mainConfig, applications, setApplications, allBd, setAllBd }} >
			{props.children}
		</MainContext.Provider>
	);
};

export default MainContextProvider;
