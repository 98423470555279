import Cookies from 'components/elements/common/Cookies';
import BlockedModal from 'components/elements/common/freemium/BlockedModal';
import MenuV3 from 'components/elements/common/freemium/MenuV3';
import { BlockerContext } from 'components/utils/BlockerContext';
import { useContext, useEffect } from 'react';
import './LayoutV2.scss';
import Menu from './Menu';

function LayoutV2(props: { children: any, className?: string, menuOpen?: boolean, isFullScreen?: boolean, leftArea: any }) {
  const { block, setBlock, unblockStep, setUnblockStep, nextStep } = useContext(BlockerContext);

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  return (
    <div className={`${props.isFullScreen ? 'fullscreen' : ''} ks-mainFrame ${props.className || ''} hp-layoutV2`}>
      <div id='hp-headerArea' className='hp-headerArea'>
        {process.env.REACT_APP_FEATURE_FREEMIUM === "1" ? (
          <MenuV3 setBlock={setBlock} />
        ) : (
          <Menu />
        )}
      </div>
      <div id='hp-leftArea' className='hp-leftArea'>
        {props.leftArea}
      </div>
      <div id='mainContentScreen' className='ks-content'>
        {props.children}
        <BlockedModal open={block} setOpen={setBlock} unblockStep={unblockStep} setUnblockStep={setUnblockStep} nextStep={nextStep} />
      </div>
      <Cookies />
    </div>
  );
}

export default LayoutV2;
