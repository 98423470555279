import { API, Auth } from "aws-amplify";

async function getOptions(initialOptions: any) {
	const finalOptions = { ...initialOptions };
	if (!finalOptions["headers"]) {
		finalOptions["headers"] = {};
	}
	const userProperties = await getCurrentUser();

	if (userProperties) {
		finalOptions["headers"]["Authorization"] =
			"Bearer " +
			userProperties.signInUserSession.idToken.jwtToken;
	}
	return finalOptions;
}

async function getCurrentUser() {
	return Auth.currentAuthenticatedUser();
	// return {
	//   signInUserBdImage: {
	//     idToken: {
	//       jwtToken: 'eyJraWQiOiJwOXRhXC94czhiWjd4UGFtUFJDVFwvOU1aTTloMDZJOGJ2akI5M1NzOVZkSDg9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI0M2QyNjc2Yy1mZjhlLTRiNTYtYjYyYy1jZDkxNDQxZTAzODkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfeDJjaE14Yk9tIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImF0bWFuYWIiLCJhdWQiOiI1YTEzbWN0ZW83Nzc2amw3b2h1cm9zdGpjbiIsImV2ZW50X2lkIjoiZDk0N2U5MWYtYjgwOC00MGU4LTk1ZGYtNThjY2NkYTNlMzIwIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MDQyNzA4NTksInBob25lX251bWJlciI6Iis0NDc4Nzk3Mjc5NjciLCJleHAiOjE2MDQyNzgyMTUsImlhdCI6MTYwNDI3NDYxNSwiZW1haWwiOiJhYmRlbGhha2ltLmF0bWFuaUBnbWFpbC5jb20ifQ.d8S7Z0IlGPjBtrnBvYG1Wn6PW4HJnPy4bPq6EUGBzoVRlX9HdjJVYiL1UPitYUWhpSv3a7jhEr1Ei7HTwr3BDMjc_g5hFv6GD1IBO-jFpgM_GsiZFCBLYU6_OiVCcyeiLYeUG_tZRwbUHBj1rd_t3KU16WOL1JHYPZgqdcEzhmh56Bqt3Rar4kuKHqKOYDspAjups5CpQ3oYyrYUlEboVX4PLiELNKQZn3A-IgjHi41zyq4cH1AOXd7pBC0g8_J8bRsaARkR0R7kLHfTS7jIx94ccSys7staNRhiitYmrezxHHDdp9yzM_RGf5FGo7WCr3HSP8IwSZEdJVgRoXbw3w'
	//     }
	//   }
	// };
}

export default class JWTApi {
	static get = async (
		apiName: string,
		path: string,
		options?: any
	) => {
		return API.get(
			apiName,
			path,
			await getOptions(options)
		);
	};

	static put = async (
		apiName: string,
		path: string,
		options: any
	) => {
		return API.put(
			apiName,
			path,
			await getOptions(options)
		);
	};

	static post = async (
		apiName: string,
		path: string,
		options: any
	) => {
		return API.post(
			apiName,
			path,
			await getOptions(options)
		);
	};
}
