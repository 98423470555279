import "./BlockedModal.scss";
import { useEffect, useState } from "react";
import { ClockLoader as Loader } from "react-spinners";

function IFrame(props: { id: string, src: string, title: string, header?: any, className?: string, paymentInfo?: any }) {
  const [loaderRef, setLoaderRef] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>();
  const {paymentInfo} = props;

  useEffect(() => {
    if (!loaded && loaderRef) {
      loaderRef.classList.remove("hideDiv");
    }
  }, [props.id, loaderRef, loaded])

  return (
    <>
      <div ref={ref => setLoaderRef(ref)} id="iframe-loader" className="loader hideDiv">
        <Loader size={60} color="black" />
      </div>
      {props.header || (<></>)}
      {loaderRef && (
        <iframe id={props.id} className={props.className}
          data-image={paymentInfo?.image}
          data-title={paymentInfo?.title}
          data-description={paymentInfo?.description}
          data-content={paymentInfo?.content}
          data-status={paymentInfo?.status}
          onLoad={() => {
            setLoaded(true);
            loaderRef.classList.add("hideDiv");
          }} src={props.src} width='100%' height='100%'
          frameBorder='none' title={props.title} />
      )}
    </>);
}

export default IFrame;
