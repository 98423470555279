import JWTApi from './JWTApi';
import { TBd, TBdImage } from '../types'
import ApiWrapper from './ApiWrapper';

const apiName = 'ApiGatewayRestApi';

export default class BaseDS {

  static getAllBds = async (): Promise<TBd[]> => {
    return ApiWrapper.get(apiName, '/main/first');
  };

  static getBdById = async (bdId: string): Promise<TBd> => {
    return ApiWrapper.get(apiName, `/bd/getById/${bdId}`);
  };

  static getFullBdById = async (bdId: string): Promise<TBd> => {
    return ApiWrapper.get(apiName, `/bd/getFullObjectById/${bdId}`);
  };

  static getFullBdImageById = async (bdId: string, sessionKey: number): Promise<TBdImage> => {
    return ApiWrapper.get(apiName, `/bd/getFullObjectById/${bdId}-${sessionKey}`);
  };

  static postError = async (error: any): Promise<any[]> => {
    return JWTApi.post(apiName, `/monitoring/error`, { body: { error } });
  };
}

