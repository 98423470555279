import MainContent from "./MainContent";
import HomeLeftArea from "./HomeLeftArea";
import LayoutV2 from "../layout/LayoutV2";
import { useState } from "react";
import { TBd } from "types";

const Homepage = (props: any) => {
	const [allDataBdImage, setAllDataBdImage] = useState<TBd>();
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const [current, setCurrent] = useState<number>(0);
	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};
	return (
		<LayoutV2 leftArea={<HomeLeftArea />} isFullScreen={isFullScreen} >
			<MainContent
				toggleFullScreen={toggleFullScreen}
				isFullScreen={isFullScreen}
				current={current}
				setCurrent={setCurrent}
				allDataBdImage={allDataBdImage}
				setAllDataBdImage={setAllDataBdImage}
				{...props} />
		</LayoutV2>
	)
};
export default Homepage;
