import "./UnblockForFree.scss";
import HooPowDS from "../internalrouter/HooPowDS";
import { ClockLoader as Loader } from "react-spinners";
import { useState } from "react";

function UnblockForFree(props: {paymentInfo: any}) {
  const [unblocking, setUnblocking] = useState<boolean>(false);

  return (<>
  <div className="hp-blockedModal-popup__unblock-forFree-banner">GRATUIT</div>
    <div className="hp-blockedModal-popup__unblock-forFree">
      <div className="hp-blockedModal-popup__unblock-forFree-left">
        <div className="hp-blockedModal-popup__unblock-forFree-text">
          En échange,<br/>
          je m’engage à parler<br/>
          de la plateforme<br/>
          Hoopow à mon<br/>
          entourage : )
        </div>
        <button className="mainBtn unblock-btn" onClick={async () => {
          setUnblocking(true);
          try {
            await HooPowDS.unblock(process.env.REACT_APP_PRODUCT_ID || "none", "full", props.paymentInfo);
            document.location.reload();
          } catch (error: any) {
            alert(error.response.data.error.message);
          }
          setUnblocking(false);
        }} disabled={unblocking}>{unblocking ? <Loader size={20} color="black" /> : "J’ACCEPTE !"}</button>
      </div>
      <div className="hp-blockedModal-popup__unblock-forFree-right">
        <img src="/free-img.png" alt="Unblock content for free." />
      </div>
    </div>
  </>);
}

export default UnblockForFree;
