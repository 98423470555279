import { ReactSVG } from "react-svg";

function PaymentHeader() {

  return (
    <>
      <div className="hp-blockedModal-popup__header">
        <ReactSVG src='/icon.svg' className="hp-blockedModal-popup__header-icon" />
        <div className="hp-blockedModal-popup__header-text">
          <div className="hp-blockedModal-popup__header-title">
            <b>Bande dessinée</b>
          </div>
          <div className="hp-blockedModal-popup__header-desc">
            Tous les jours, une histoire en format BD.
          </div>
          <div className="hp-blockedModal-popup__header-content">
            <b>Contenu : </b>50 épisodes
          </div>
          <div className="hp-blockedModal-popup__header-state">
            <b>État : </b>en cours
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentHeader;
